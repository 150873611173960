import React, { useState } from "react";
import apiClient from "../../Helpers/apiClient";

const GenerateTemporaryLinkPage = () => {
    const [generatedLink, setGeneratedLink] = useState("");

    const handleGenerateLink = async () => {
        try {
            const response = await apiClient.post(
                "/api/projectuser/generate-link"
            );
            setGeneratedLink(response.data.link);
        } catch (error) {
            console.error("Error generating link:", error);
            alert("Failed to generate link.");
        }
    };

    return (
        <div>
            <h1>Generate Temporary Link</h1>
            <button onClick={handleGenerateLink}>Generate Link</button>
            {generatedLink && (
                <div>
                    <h2>Generated Link:</h2>
                    <a
                        href={generatedLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {generatedLink}
                    </a>
                </div>
            )}
        </div>
    );
};

export default GenerateTemporaryLinkPage;
