import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Container,
    Paper,
    Typography,
    TextField,
    Button,
    Box,
    CircularProgress,
    Alert,
    Stack,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Snackbar,
} from "@mui/material";
import apiClient from "../../Helpers/apiClient";

// Define the ProductName enum values
const ProductName = {
    TRAP_IT: "TRAP_IT",
    ECOPROTECTOR: "ECOPROTECTOR",
    Tornado_GPT: "Tornado_GPT",
    HYDROPROTECTOR: "HYDROPROTECTOR",
    STORMBRAKE: "STORMBRAKE",
    DRAINSHIELD: "DRAINSHIELD",
    XTREAMFILTER: "XTREAMFILTER",
    ENVIROPROTECTOR: "ENVIROPROTECTOR",
    ENVIROSAVE: "ENVIROSAVE",
    STORMPROTECTOR: "STORMPROTECTOR",
    HYDROVAULT: "HYDROVAULT",
    PACKAGED_PUMP_STATIONS: "PACKAGED_PUMP_STATIONS",
    None: "None",
};

const EditProjectPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });
    const [formData, setFormData] = useState({
        description: "",
        productCategory: "None",
    });

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await apiClient.get(
                    `/api/projectuser/projects/${id}`
                );
                setFormData({
                    description: response.data.description || "",
                    productCategory: response.data.productCategory || "None",
                });
            } catch (error) {
                console.error("Error fetching project:", error);
                setError(
                    typeof error.response?.data === "string"
                        ? error.response.data
                        : "Failed to load project. Please try again."
                );
            } finally {
                setLoading(false);
            }
        };

        fetchProject();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");

        try {
            await apiClient.put(`/api/projectuser/projects/${id}`, formData);
            setSnackbar({
                open: true,
                message: "Project updated successfully!",
                severity: "success",
            });
            setTimeout(() => {
                navigate("/warrenty");
            }, 1500); // Navigate after 1.5 seconds so user can see success message
        } catch (error) {
            console.error("Error updating project:", error);
            const errorMessage =
                typeof error.response?.data === "string"
                    ? error.response.data
                    : "Failed to update project. Please try again.";
            setSnackbar({
                open: true,
                message: errorMessage,
                severity: "error",
            });
        }
    };

    if (loading) {
        return (
            <Container maxWidth="sm">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh"
                >
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="sm">
            <Box py={4}>
                <Paper elevation={3}>
                    <Box p={4}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            Edit Project
                        </Typography>

                        {error && (
                            <Box mb={2}>
                                <Alert severity="error">{error}</Alert>
                            </Box>
                        )}

                        <form onSubmit={handleSubmit}>
                            <Stack spacing={3}>
                                <TextField
                                    fullWidth
                                    label="Description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                    multiline
                                    rows={4}
                                    placeholder="Enter project description..."
                                />

                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id="product-category-label">
                                        Product Category
                                    </InputLabel>
                                    <Select
                                        labelId="product-category-label"
                                        name="productCategory"
                                        value={formData.productCategory}
                                        onChange={handleInputChange}
                                        label="Product Category"
                                    >
                                        {Object.values(ProductName).map(
                                            (category) => (
                                                <MenuItem
                                                    key={category}
                                                    value={category}
                                                >
                                                    {category.replace(
                                                        /_/g,
                                                        " "
                                                    )}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>

                                <Box display="flex" gap={2}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                    >
                                        Save Changes
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        fullWidth
                                        onClick={() => navigate("/warrenty")}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </Stack>
                        </form>
                    </Box>
                </Paper>
            </Box>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default EditProjectPage;
