import React from "react";
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";

const EcoprotectorSpecificationsUI = () => {
    return (
        <Box
            sx={{
                display: "flex",
                height: "100vh", // Changed to viewport height
                minHeight: "100vh", // Changed to viewport height
                //  overflow: "auto",
                width: "100%", // Add this
                overflow: "auto", // Add this

                mb: 5, // Add margin bottom
            }}
        >
            <Box
                sx={{
                    width: "33.33%",
                    flex: "0 0 33.33%",
                    padding: "25px",
                    overflow: "auto",
                    minWidth: 0, // Add this to allow shrinking
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 700,
                        // color: "#1c6790",
                        mb: 2,

                        verticalAlign: "50%",
                    }}
                >
                    1. Product Construction and Materials
                </Typography>
                <Typography
                    sx={{
                        verticalAlign: "50%",
                        lineHeight: "1.3",
                        mb: 1,
                    }}
                >
                    The EcoProtector is a robust and durable stormwater
                    treatment dummytext dymmytext and performance in mind. Key
                    details include:
                </Typography>
                <Typography
                    sx={{
                        verticalAlign: "50%",
                        lineHeight: "1.3",
                        mb: 1,
                    }}
                >
                    <Box component={"span"} sx={{ fontWeight: 700 }}>
                        • Construction Material:
                    </Box>{" "}
                    Manufactured from lightweight, dummytext dummytext dummytext
                    Reinforced Plastic (FRP).
                </Typography>
                <Typography
                    sx={{
                        verticalAlign: "50%",
                        lineHeight: "1.3",
                        mb: 1,
                    }}
                >
                    <Box component={"span"} sx={{ fontWeight: 700 }}>
                        • Dimensions and Weight:
                    </Box>{" "}
                    Available in a range of sizes to accommodate varying flow
                    rates and site conditions.
                </Typography>
                <Typography
                    sx={{
                        verticalAlign: "50%",
                        lineHeight: "1.3",
                        mb: 1,
                    }}
                >
                    <Box component={"span"} sx={{ fontWeight: 700 }}>
                        • Environmental Sustainability:
                    </Box>{" "}
                    Designed to minimize environmental impact, featuring
                    recyclable components and optimized to enhance water quality
                    in urban and industrial runoff scenarios.
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 700,
                        color: "#1c6790",
                        mt: 4,
                        verticalAlign: "50%",
                    }}
                >
                    ECOPROTECTOR SPECIFICATION{" "}
                </Typography>

                <TableContainer
                    component={Paper}
                    sx={{
                        maxWidth: "100%",
                        overflowX: "auto",
                        boxShadow: "none",
                        "& .MuiTable-root": {
                            minWidth: "auto", // Remove minimum width
                            fontSize: "0.75rem", // Make text smaller
                        },
                    }}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow
                                sx={{
                                    "& th": {
                                        fontWeight: "bold",
                                        color: "#4A4A4A",
                                        textAlign: "center",
                                        padding: "12px 8px",
                                        borderBottom: "1px solid #ccc",
                                    },
                                }}
                            >
                                <TableCell>MODEL ID</TableCell>
                                <TableCell>
                                    TREATMENT
                                    <br />
                                    FLOW RATE
                                    <br />
                                    (L/S)
                                </TableCell>
                                <TableCell>
                                    BYPASS FLOW
                                    <br />
                                    (L/S)
                                </TableCell>
                                <TableCell>
                                    UNIT DIA
                                    <br />
                                    (MM)
                                </TableCell>
                                <TableCell>
                                    UNIT DEPTH
                                    <br />
                                    (MM)
                                </TableCell>
                                <TableCell>
                                    BASE TO INLET
                                    <br />
                                    INVERT (MM)
                                </TableCell>
                                <TableCell>
                                    UNIT WEIGHT
                                    <br />
                                    (KG)
                                </TableCell>
                                <TableCell>
                                    STANDARD
                                    <br />
                                    PIPEWORK DIA
                                    <br />
                                    (MM)
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[
                                {
                                    id: "ECP.1200",
                                    flow: 32,
                                    bypass: 165,
                                    dia: 1200,
                                    depth: 2200,
                                    inlet: 1725,
                                    weight: 260,
                                    pipe: "100 - 375",
                                },
                                {
                                    id: "ECP.1500",
                                    flow: 105,
                                    bypass: 480,
                                    dia: 1500,
                                    depth: 2400,
                                    inlet: 1800,
                                    weight: 480,
                                    pipe: "100 - 375",
                                },
                                {
                                    id: "ECP.1850",
                                    flow: 135,
                                    bypass: 1020,
                                    dia: 1850,
                                    depth: 2550,
                                    inlet: 2000,
                                    weight: 680,
                                    pipe: "225 - 600",
                                },
                                {
                                    id: "ECP.2200",
                                    flow: 288,
                                    bypass: 2900,
                                    dia: 2200,
                                    depth: 2550,
                                    inlet: 2350,
                                    weight: 1140,
                                    pipe: "375 - 900",
                                },
                                {
                                    id: "ECP.2500",
                                    flow: 448,
                                    bypass: 9500,
                                    dia: 2500,
                                    depth: 3500,
                                    inlet: 2700,
                                    weight: 2000,
                                    pipe: "600 - 1350",
                                },
                                {
                                    id: "ECP.3000",
                                    flow: 894,
                                    bypass: 13780,
                                    dia: 2500,
                                    depth: 4500,
                                    inlet: 3400,
                                    weight: 3300,
                                    pipe: "600 - 1500",
                                },
                                {
                                    id: "ECP.3500",
                                    flow: 1905,
                                    bypass: 22118,
                                    dia: 3500,
                                    depth: 5000,
                                    inlet: 4100,
                                    weight: 5300,
                                    pipe: "600 - 1800",
                                },
                            ].map((row, index) => (
                                <TableRow
                                    key={row.id}
                                    sx={{
                                        backgroundColor:
                                            index % 2 === 1
                                                ? "#f5f5f5"
                                                : "white",
                                        "& td": {
                                            textAlign: "center",
                                            padding: "12px 8px",
                                            borderBottom: "1px solid #e0e0e0",
                                        },
                                    }}
                                >
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.flow}</TableCell>
                                    <TableCell>{row.bypass}</TableCell>
                                    <TableCell>{row.dia}</TableCell>
                                    <TableCell>{row.depth}</TableCell>
                                    <TableCell>{row.inlet}</TableCell>
                                    <TableCell>{row.weight}</TableCell>
                                    <TableCell>{row.pipe}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box
                sx={{
                    width: "33.33%",
                    flex: "0 0 33.33%",
                    padding: "25px",
                    overflow: "auto",
                    backgroundColor: "#ededed",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 700,
                        // color: "#1c6790",
                        mb: 2,

                        verticalAlign: "50%",
                    }}
                >
                    2. Performance and Certification
                </Typography>
                <Typography
                    sx={{
                        verticalAlign: "50%",
                        lineHeight: "1.3",
                        mb: 1,
                    }}
                >
                    Protector has undergone rigorous testing to ensure it meets
                    the highest performance standard. Key specifications
                    include:
                </Typography>
                <Typography
                    sx={{
                        verticalAlign: "50%",
                        lineHeight: "1.3",
                        mb: 1,
                    }}
                >
                    <Box component={"span"} sx={{ fontWeight: 700 }}>
                        • dummytext Flow Rate:{" "}
                    </Box>{" "}
                    Configurable to manage site-specific flow rates effectively.
                </Typography>
                <Typography
                    sx={{
                        verticalAlign: "50%",
                        lineHeight: "1.3",
                        mb: 1,
                    }}
                >
                    <Box component={"span"} sx={{ fontWeight: 700 }}>
                        • Pollutant Removal Efficiency:
                    </Box>{" "}
                    Proven to capture hydrocarbons, suspended solids, and other
                    pollutants at industry-leading rates.
                </Typography>
                <Typography
                    sx={{
                        verticalAlign: "50%",
                        lineHeight: "1.3",
                        mb: 1,
                    }}
                >
                    <Box component={"span"} sx={{ fontWeight: 700 }}>
                        • Certification:
                    </Box>{" "}
                    Certified to meet EN858 Class 1 standards for hydrocarbon
                    separation and environmental protection.
                </Typography>
                <Typography
                    sx={{
                        verticalAlign: "50%",
                        lineHeight: "1.3",
                        mb: 1,
                    }}
                >
                    <Box component={"span"} sx={{ fontWeight: 700 }}>
                        • Bypass Capacity:
                    </Box>{" "}
                    Equipped with an intemal bypass system to manage peak storm
                    events without compromising treatment efficacy.
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        gap: { xs: 1, sm: 1 },
                        alignItems: "center",
                        flexWrap: "nowrap",
                        justifyContent: "space-between",
                        width: "100%",
                        overflow: "hidden", // Prevents overflow
                        mt: 5,
                    }}
                >
                    <Box
                        component="img"
                        src="/imgs/ECP Product Specifications Overview.png"
                        alt="ECP Product Specifications Overview"
                        sx={{
                            flex: "1 1 auto",
                            minWidth: 0, // Important: allows image to shrink below content size
                            maxWidth: "33%", // Ensures each image takes up equal space
                            height: "auto",
                            maxHeight: "270px",
                            objectFit: "contain",
                            borderRadius: 1,
                        }}
                    />
                    <Box
                        component="img"
                        src="/imgs/ECP Product Specifications Overview2.png"
                        alt="ECP Product Specifications Overview2"
                        sx={{
                            flex: "1 1 auto",
                            minWidth: 0,
                            maxWidth: "33%",
                            height: "auto",
                            maxHeight: "270px",
                            objectFit: "contain",
                            borderRadius: 1,
                        }}
                    />
                    <Box
                        component="img"
                        src="/imgs/ECP Product Specifications Overview3.png"
                        alt="ECP Product Specifications Overview3"
                        sx={{
                            flex: "1 1 auto",
                            minWidth: 0,
                            maxWidth: "33%",
                            height: "auto",
                            maxHeight: "270px",
                            objectFit: "contain",
                            borderRadius: 1,
                        }}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    width: "33.33%",
                    flex: "0 0 33.33%",
                    padding: "25px",
                    overflow: "auto",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 700,
                        // color: "#1c6790",
                        mb: 2,

                        verticalAlign: "50%",
                    }}
                >
                    3. Installation and Maintenance
                </Typography>
                <Typography
                    sx={{
                        verticalAlign: "50%",
                        lineHeight: "1.3",
                        mb: 1,
                    }}
                >
                    The EcoProtector is engineered for straightforward
                    installation and low-maintenance operation. Key features
                    include:
                </Typography>
                <Typography
                    sx={{
                        verticalAlign: "50%",
                        lineHeight: "1.3",
                        mb: 1,
                    }}
                >
                    <Box component={"span"} sx={{ fontWeight: 700 }}>
                        • Modular Design:
                    </Box>{" "}
                    Simplifies installation and allows for integration with
                    existing drainage systems.
                </Typography>
                <Typography
                    sx={{
                        verticalAlign: "50%",
                        lineHeight: "1.3",
                        mb: 1,
                    }}
                >
                    <Box component={"span"} sx={{ fontWeight: 700 }}>
                        • Accessibility:
                    </Box>{" "}
                    Easy-to-clean and inspect, with access points designed for
                    standard maintenance procedures.
                </Typography>
                <Typography
                    sx={{
                        verticalAlign: "50%",
                        lineHeight: "1.3",
                        mb: 1,
                    }}
                >
                    <Box component={"span"} sx={{ fontWeight: 700 }}>
                        • Durability:
                    </Box>{" "}
                    Resistant to harsh environmental conditions, ensuring
                    long-term reliability and reduced maintenance costs.
                </Typography>
                <Typography
                    sx={{
                        verticalAlign: "50%",
                        lineHeight: "1.3",
                        mb: 1,
                    }}
                >
                    <Box component={"span"} sx={{ fontWeight: 700 }}>
                        • Lifecycle Costs:
                    </Box>{" "}
                    Optimized to provide cost-effective stormwater management
                    solutions with minimal operating costs over its lifespan.
                </Typography>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        overflow: "hidden",
                        mt: 5,
                    }}
                >
                    <Box
                        component="img"
                        src="/imgs/ECP Product Specifications Overview4.png"
                        alt=""
                        sx={{
                            flex: "1 1 auto",
                            minWidth: 0,
                            maxWidth: "50%", // Increased from 33% to 50%
                            height: "auto",
                            maxHeight: "320px", // Increased from 250px to 300px
                            objectFit: "contain",
                            borderRadius: 1,
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default EcoprotectorSpecificationsUI;
