import React from "react";
import { Typography, Box, Button } from "@mui/material";

const EcoprotectorDrawingDesignToolUI = () => {
    const openDesignTool = (event) => {
        event.stopPropagation();

        const jwtToken = localStorage.getItem("jwtToken");

        if (jwtToken) {
            const form = document.createElement("form");
            form.method = "POST";
            //  form.action = "http://localhost:5186/";
            form.action = process.env.REACT_APP_API_URL;

            // Create a hidden input with the token
            const hiddenField = document.createElement("input");
            hiddenField.type = "hidden";
            hiddenField.name = "token";
            hiddenField.value = jwtToken;

            form.appendChild(hiddenField);
            document.body.appendChild(form);
            form.target = "_blank"; // Open in a new tab
            form.submit();

            document.body.removeChild(form); // Clean up the DOM
        } else {
            console.error("No token found in local storage.");
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                height: "100%", // For taking full height of the parent container
                minHeight: "100%", // Fixed height even when no content
                overflow: "scroll",
            }}
        >
            <Box
                sx={{
                    flexGrow: 1,
                    height: "100%",
                    minHeight: "100%",
                    padding: "25px",
                    width: "40%",
                }}
            >
                <Typography
                    sx={{
                        mb: 4,
                        ml: 3,
                        lineHeight: 1.2,
                        fontFamily: "'Calibri', sans-serif",
                        fontWeight: 500,
                        fontSize: "18px",
                        color: "#1a1a1a",
                    }}
                >
                    The Product Configurator is designed to help you customize,
                    generate, and save specifications and drawings for your
                    stormwater solutions. Follow the steps below to quickly set
                    up your project and download the necessary files.
                </Typography>

                {/* Main Title */}
                <Typography
                    variant="h5"
                    sx={{ fontWeight: 500, mb: 3, lineHeight: 1.2 }}
                >
                    Step-by-Step Guide
                </Typography>

                {/* Step 1 */}
                <Box sx={{ ml: 3 }}>
                    <Box sx={{ mb: 2 }}>
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: 500, mb: 1, lineHeight: 1.2 }}
                        >
                            Step 1: Access the Configurator
                        </Typography>
                        <Box sx={{ ml: 3 }}>
                            <Typography
                                sx={{ fontWeight: 500, mb: 1, lineHeight: 1.2 }}
                            >
                                • Direct users to log in or navigate to the
                                configurator.
                            </Typography>
                            <Typography
                                sx={{ fontWeight: 500, mb: 1, lineHeight: 1.2 }}
                            >
                                • Include a button like: [Open Configurator].
                            </Typography>
                        </Box>
                    </Box>

                    {/* Step 2 */}
                    <Box sx={{ mb: 2 }}>
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: 500, mb: 1, lineHeight: 1.2 }}
                        >
                            Step 2: Create or Select a Project Folder
                        </Typography>
                        <Box sx={{ ml: 3 }}>
                            <Typography
                                sx={{ fontWeight: 500, mb: 1, lineHeight: 1.2 }}
                            >
                                • If you are starting a new project:
                            </Typography>
                            <Box sx={{ ml: 3, mb: 1 }}>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        mb: 1,
                                        lineHeight: 1.2,
                                    }}
                                >
                                    • Click the "Create New Project" button.
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        mb: 1,
                                        lineHeight: 1.2,
                                    }}
                                >
                                    • Enter a name for your project (e.g., "7
                                    Lackey Road, Moss Vale").
                                </Typography>
                            </Box>
                            <Typography
                                sx={{ fontWeight: 500, mb: 1, lineHeight: 1.2 }}
                            >
                                • If you have an existing project:
                            </Typography>
                            <Box sx={{ ml: 3 }}>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        mb: 1,
                                        lineHeight: 1.2,
                                    }}
                                >
                                    • Use the dropdown menu to select the
                                    appropriate folder.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    {/* Step 3 */}
                    <Box sx={{ mb: 2 }}>
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: 500, mb: 1, lineHeight: 1.2 }}
                        >
                            Step 3: Input Design Parameters
                        </Typography>
                        <Box sx={{ ml: 3 }}>
                            <Typography
                                sx={{ fontWeight: 500, mb: 1, lineHeight: 1.2 }}
                            >
                                • Fill in the required details:
                            </Typography>
                            <Box sx={{ ml: 3 }}>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        mb: 1,
                                        lineHeight: 1.2,
                                    }}
                                >
                                    • Treatable Flow Rate: Select a value (e.g.,
                                    5 L/s, 20 L/s).
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        mb: 1,
                                        lineHeight: 1.2,
                                    }}
                                >
                                    • Bypass Flow Rate: Choose the appropriate
                                    option.
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        mb: 1,
                                        lineHeight: 1.2,
                                    }}
                                >
                                    • Connection Type: Specify inlet/outlet
                                    configurations.
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        mb: 1,
                                        lineHeight: 1.2,
                                    }}
                                >
                                    • Cover Type: Select surface load
                                    requirement (e.g., Class B or Class D).
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    {/* Step 4 */}
                    <Box sx={{ mb: 2 }}>
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: 500, mb: 1, lineHeight: 1.2 }}
                        >
                            Step 4: Generate and Save Files
                        </Typography>
                        <Box sx={{ ml: 3 }}>
                            <Typography
                                sx={{ fontWeight: 500, mb: 1, lineHeight: 1.2 }}
                            >
                                • After entering the parameters:
                            </Typography>
                            <Box sx={{ ml: 3 }}>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        mb: 1,
                                        lineHeight: 1.2,
                                    }}
                                >
                                    • Click on the file type you need (PDF, DWG,
                                    3D DWG, RVT).
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        mb: 1,
                                        lineHeight: 1.2,
                                    }}
                                >
                                    • The system will:
                                </Typography>
                                <Box sx={{ ml: 3 }}>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            mb: 1,
                                            lineHeight: 1.2,
                                        }}
                                    >
                                        • Generate the selected file type.
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            mb: 1,
                                            lineHeight: 1.2,
                                        }}
                                    >
                                        • Automatically save it to your chosen
                                        project folder.
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography
                                sx={{ fontWeight: 500, mb: 1, lineHeight: 1.2 }}
                            >
                                • Confirm the file has been saved using the
                                status message.
                            </Typography>
                        </Box>
                    </Box>

                    {/* Step 5 */}
                    <Box sx={{ mb: 2 }}>
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: 500, mb: 1, lineHeight: 1.2 }}
                        >
                            Step 5: Manage and Revisit Project Files
                        </Typography>
                        <Box sx={{ ml: 3 }}>
                            <Typography
                                sx={{ fontWeight: 500, mb: 1, lineHeight: 1.2 }}
                            >
                                • Navigate back to your project folder anytime
                                to:
                            </Typography>
                            <Box sx={{ ml: 3 }}>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        mb: 1,
                                        lineHeight: 1.2,
                                    }}
                                >
                                    • Edit existing configurations.
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        mb: 1,
                                        lineHeight: 1.2,
                                    }}
                                >
                                    • Download files again as needed.
                                </Typography>
                            </Box>
                            <Typography
                                sx={{ fontWeight: 500, mb: 1, lineHeight: 1.2 }}
                            >
                                • Use the search bar or folder list to quickly
                                locate your project.
                            </Typography>
                        </Box>
                    </Box>

                    {/* Tips Section */}
                    <Box>
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: 500, mb: 1, lineHeight: 1.2 }}
                        >
                            Tips and Best Practices
                        </Typography>
                        <Box sx={{ ml: 3 }}>
                            <Typography
                                sx={{ fontWeight: 500, mb: 1, lineHeight: 1.2 }}
                            >
                                • Ensure all input fields are correctly filled
                                to avoid errors in file generation.
                            </Typography>
                            <Typography
                                sx={{ fontWeight: 500, mb: 1, lineHeight: 1.2 }}
                            >
                                • Use descriptive names for project folders to
                                easily identify them later.
                            </Typography>
                            <Typography
                                sx={{ fontWeight: 500, mb: 1, lineHeight: 1.2 }}
                            >
                                • Download files in the format required by your
                                design software.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    padding: "20px",
                    backgroundColor: "#f5f5f5",
                    color: "#333",
                    fontFamily: "'Roboto', sans-serif",
                    flexGrow: 2,
                    height: "1200px",
                    width: "60%",
                    display: "flex",
                    justifyContent: "center", // Keep the button horizontally centered
                    alignItems: "flex-start", // Align the content to the top vertically
                    position: "relative", // Relative positioning for label overlay
                }}
            >
                <Button
                    variant="outlined"
                    onClick={(e) => openDesignTool(e)}
                    sx={{
                        backgroundColor: "#185576", // Background color
                        borderColor: "#646565", // Border color
                        borderWidth: "8px", // Thickness of the border (doubled)
                        color: "#ffffff", // Text color
                        textTransform: "none", // Keep text as is (no uppercase)
                        borderRadius: "16px", // Rounded corners (doubled)
                        padding: "24px 48px", // Padding inside the button (doubled)
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "32px", // Space between the icon and text (doubled)
                        mt: 16, // Add top margin to position the button below the top of the container
                        "&:hover": {
                            backgroundColor: "#104c66", // Darker shade on hover
                            borderColor: "#525454", // Slightly darker border on hover
                        },
                    }}
                >
                    {/* Icon */}
                    <Box
                        component="img"
                        src="/imgs/ecoprotectorbutton.png" // Replace with your icon path
                        alt="EcoProtector Icon"
                        sx={{
                            width: "80px", // Doubled width
                            height: "80px", // Doubled height
                            borderRadius: "8px", // Optional, doubled for consistency
                            backgroundColor: "#ffffff",
                            padding: "8px", // Add space around the icon (doubled)
                        }}
                    />

                    {/* Text */}
                    <Typography
                        sx={{
                            fontSize: "36px", // Doubled font size
                            fontWeight: "bold",
                        }}
                    >
                        Product Configurator
                    </Typography>
                </Button>
            </Box>
        </Box>
    );
};

export default EcoprotectorDrawingDesignToolUI;
