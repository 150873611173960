import React from "react";
import ProductHomeNavBar from "./productHomeNavBar";
import LoginIcon from "@mui/icons-material/Login";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import ConstructionIcon from "@mui/icons-material/Construction";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules"; // For auto-swiping
import "swiper/css/pagination";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { Navigation, Pagination, EffectFade } from "swiper/modules";
import { Box, Typography } from "@mui/material";

const ProductHomePage = () => {
    // const slides = [
    //     {
    //         background:
    //             "url(https://via.placeholder.com/800x400/FF5733/FFFFFF)",
    //         alt: "Slide 1",
    //     },
    //     {
    //         background:
    //             "url(https://via.placeholder.com/800x400/33C1FF/FFFFFF)",
    //         alt: "Slide 2",
    //     },
    //     {
    //         background:
    //             "url(https://via.placeholder.com/800x400/75FF33/FFFFFF)",
    //         alt: "Slide 3",
    //     },
    // ];

    const slides = [
        {
            background:
                "url('/imgs/home_page_swper_imgs/01shutterstock_1009287067 greyscale.jpg')",
            title: "STORMPROTECTOR",
            description:
                "HAVE A SITE WITH POTENTIAL FOR MEDIUM RISK, HIGH POLLUTANT STORMWATER FLOW?",
            productImage: "01.png",
        },
        {
            background:
                "url('/imgs/home_page_swper_imgs/02Perrys Tinman Fuel Station greyscale with Overlay.jpg')",
            title: "HYDROPROTECTOR",
            description:
                "SPECIFYING A HIGH RISK, CLASS 1 HYDROCARBON SITE WITH CONTAINMENT REQUIRMENTS?",
            productImage: "02.png",
        },
        {
            background:
                "url('/imgs/home_page_swper_imgs/03shutterstock_294116876 Greyscale with Overlay.jpg')",
            title: "ECOPROTECTOR",
            description:
                "NEED TO PROTECT A WATER COURSE FROM LOW RISK GROSS POLLUTANT STORMWATER?",
            productImage: "03.png",
        },
        {
            background:
                "url('/imgs/home_page_swper_imgs/04shutterstock_1190944876 greyscale with overlay.jpg')",
            title: "BIOPROTECTOR",
            description:
                "LOOKING FOR A COVERT WAY TO PHYSICALLY AND BIOLOGICALLY TREAT STORMWATER IN A STREETSCAPE?",
            productImage: "04.png",
        },
        {
            background:
                "url('/imgs/home_page_swper_imgs/05shutterstock_2228970383 Greyscale with Overlay.jpg')",
            title: "ENVIROPROTECTOR",
            description:
                "NEED TO REMOVE NUTRIENT OVERLOADING TO PROTECT A WATERCOURSE?",
            productImage: "05.png",
        },
        {
            background:
                "url('/imgs/home_page_swper_imgs/06shutterstock_1965314032 greyscale with Overlay.jpg')",
            title: "ENVIROSAVE",
            description:
                "SHORT OF SPACE BUT NEED TO CAPTURE GROSS POLUTANTS IN HIGH FLOWS AND NUTRIENTS IN OFFLINE FLOWS?",
            productImage: "06.png",
        },
        {
            background:
                "url('/imgs/home_page_swper_imgs/07shutterstock_1135910192 Greyscale with Overlay.jpg')",
            title: "TRAP-IT",
            description:
                "CAPUTRE GROSS POLLUTANTS AT THE CONVENIENTLY ACCESSABLE INLET OF THE STORMWATER SYSTEM?",
            productImage: "07.png",
        },
        {
            background:
                "url('/imgs/home_page_swper_imgs/08shutterstock_57633475 Greyscale with 08Overlay.jpg')",
            title: "XTREAMFILTER",
            description:
                "HAVE A SITE WITH HEAVY NUTRIENT OVERLOAD WITH THE OPTION TO EXPAND SYSTEM FOR FUTURE REQUIRMENTS?",
            productImage: "08.png",
        },
        {
            background:
                "url('/imgs/home_page_swper_imgs/09shutterstock_159574640 Greyscale.jpg')",
            title: "TORNADO GPT",
            description:
                "ENVIRONMENT REQUIRE REMOVAL OF GROSS POLLUTANTS FROM HIGH FLOW, LOW RISK STORMWATER?",
            productImage: "09.png",
        },
        {
            background:
                "url('/imgs/home_page_swper_imgs/10shutterstock_1838571982 greyscale with Overlay.jpg')",
            title: "HYDROVAULT",
            description:
                "NEED LIGHT-WEIGHT, RELIABLE, MODULAR STORMWATER STORAGE FOR HIGH-VOLUME ONSITE DETENTION NEEDS?",
            productImage: "10.png",
        },
        {
            background:
                "url('/imgs/home_page_swper_imgs/11shutterstock_2288665475 Greyscale with Overlay.jpg')",
            title: "XTREAMPROTECTOR",
            description:
                "NEED TO MANAGE HIGH- RISK POLLUTANTS IN CHALLENGING STORMWATER CONDITIONS?",
            productImage: "11.png",
        },
        {
            background:
                "url('/imgs/home_page_swper_imgs/12shutterstock_1743191609 greyscale with Overlay.jpg')",
            title: "STORMBRAKE",
            description:
                "NEED A MODULAR BIOFILTRATION STORMWATER RETENTION SYSTEM TO MEET SITE WSUD REQUIREMENTS?",
            productImage: "12.png",
        },
        {
            background:
                "url('/imgs/home_page_swper_imgs/13shutterstock_1852666147 Greyscale with Overlay.png')",
            title: "PUMP STATION",
            description:
                "NEED A TAILORED PUMP STATION FOR COMPLEX STORMWATER CHALLENGES?",
            productImage: "13.png",
        },
        {
            background:
                "url('/imgs/home_page_swper_imgs/14shutterstock_141689395 Greyscale with overlay.png')",
            title: "DRAINSHIELD",
            description:
                "REQUIRE REMOVAL OF GREASE AND OIL FROM A TRADE WASTE WATER FLOW?",
            productImage: "14.png",
        },
    ];

    // Add more slides as needed

    const commonBackgroundStyle = {
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100%",
        objectFit: "cover",
    };

    const overlayStyle = {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(0, 0, 0, 0.3)", // reduced opacity to see the blue overlay better
        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
        color: "white",
        // textAlign: "center",
        padding: "20px",
        paddingLeft: "50px",
        zIndex: 3, // ensure text stays on top
    };

    const blueOverlayStyle = {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundImage:
            "url('/imgs/home_page_swper_imgs/Blue-Overlay-2-1.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        zIndex: 2, // middle layer between background and text
    };

    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div>
            <ProductHomeNavBar />
            <div className="container">
                {/* <img
                    src="/imgs/fakebanner.png"
                    alt=""
                    style={{ width: "100%", height: "auto", objectFit: "fill" }}
                /> */}
                <Swiper
                    modules={[Autoplay]}
                    autoplay={{ delay: 5500, disableOnInteraction: false }}
                    loop
                    slidesPerView={1}
                    speed={1200}
                    onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                    style={{ width: "100%", height: "550px", zIndex: 0 }}
                >
                    {slides.map((slide, index) => (
                        <SwiperSlide
                            key={index}
                            style={{
                                backgroundImage: slide.background,
                                ...commonBackgroundStyle,
                                position: "relative",
                                zIndex: 0,
                            }}
                        >
                            <AnimatePresence mode="sync">
                                {activeIndex === index && (
                                    <>
                                        {/* Blue Overlay with sliding animation */}
                                        <motion.div
                                            key={`overlay-${index}`}
                                            initial={{ x: "100%" }}
                                            animate={{ x: "-35%" }} // Changed to stop at 50% off screen
                                            exit={{ x: "-135%" }} // Adjusted exit to maintain consistency
                                            transition={{
                                                duration: 1.2,
                                                delay: 1.5,
                                            }}
                                            style={blueOverlayStyle}
                                        />

                                        {/* Text content */}
                                        <Box sx={overlayStyle}>
                                            <motion.div
                                                key={`title-${index}`}
                                                initial={{ opacity: 0, y: -50 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                exit={{ opacity: 0, y: 50 }}
                                                transition={{
                                                    duration: 1.8,
                                                    delay: 2,
                                                }}
                                            >
                                                <Typography
                                                    variant="h3"
                                                    sx={{ mb: 6, mt: 6 }}
                                                >
                                                    {slide.title}
                                                </Typography>
                                            </motion.div>
                                            <motion.div
                                                key={`desc-${index}`}
                                                initial={{ opacity: 0, y: 50 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                exit={{ opacity: 0, y: -50 }}
                                                transition={{
                                                    duration: 1.8,
                                                    delay: 2,
                                                }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        mb: 2,
                                                        width: "370px",
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {slide.description}
                                                </Typography>
                                            </motion.div>

                                            <motion.div
                                                key={`desc-${index}`}
                                                initial={{ opacity: 0, y: 50 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                exit={{ opacity: 0, y: -50 }}
                                                transition={{
                                                    duration: 1.8,
                                                    delay: 2,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        mt: 8,
                                                    }}
                                                >
                                                    <img // Added image tag to display product imag  in the slider
                                                        src={
                                                            "/imgs/home_page_swper_imgs/Settings Icon.png"
                                                        }
                                                        alt=""
                                                        style={{
                                                            width: "20px",
                                                            height: "20px",
                                                            // objectFit: "contain",
                                                        }}
                                                    />
                                                    <Typography
                                                        variant="p"
                                                        sx={{ ml: 2 }}
                                                    >
                                                        CREATE YOUR
                                                        SPECIFICATION
                                                    </Typography>
                                                </Box>
                                            </motion.div>
                                        </Box>
                                        <motion.div
                                            key={`desc-${index}`}
                                            initial={{ opacity: 0, x: 100 }} // Changed y to x, starts from 100px right
                                            animate={{ opacity: 1, x: 0 }} // Moves to original position
                                            exit={{ opacity: 0, x: -100 }} // Exits to the left
                                            transition={{
                                                duration: 1.8,
                                                delay: 1.5,
                                            }}
                                            style={{
                                                position: "absolute",
                                                bottom: "2rem",
                                                right: "2rem",
                                                zIndex: 10,
                                            }}
                                        >
                                            <img
                                                src={`/imgs/home_page_swper_imgs/${slide.productImage}`}
                                                alt=""
                                                style={{
                                                    width: "450px",
                                                    height: "450px",
                                                    objectFit: "contain",
                                                }}
                                            />
                                        </motion.div>
                                    </>
                                )}
                            </AnimatePresence>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div
                    className="section1"
                    style={{ backgroundColor: "white", paddingTop: "80px" }}
                >
                    <div className="article_wrapper">
                        <h2 className="title">SPECIFYING PROTECTOR®</h2>
                        <p className="paragraph-blue" style={{ color: "grey" }}>
                            The Specifying Protector Portal is an online
                            repository for Fielders® product literature, making
                            our product literature accessible whenever and
                            wherever you need.
                        </p>
                        <p
                            className="paragraph-gray"
                            style={{
                                color: "black",
                                fontWeight: "400",
                                lineHeight: "1.3em",
                            }}
                        >
                            Our products are renowned for their quality and are
                            engineered to Australian standards BS4994-1987 and
                            ASME RPT1. Protector has improved the ease of device
                            installation by eliminating the need for confined
                            space entry and the requirement to access the base
                            of site excavations.
                        </p>
                        <p
                            className="paragraph-gray"
                            style={{
                                color: "black",
                                fontWeight: "400",
                                lineHeight: "1.3em",
                            }}
                        >
                            Protector products are constructed using the
                            advanced chop hoop filament winding process which
                            ensures circumferential as well as longitudinal
                            strength. Every Protector product has a smooth
                            molded resin rich inner corrosion barrier layer and
                            an external resin barrier. Being manufactured in FRP
                            (Fibre Reinforced Plastic) Protector products are
                            light, easy to handle and easy to instal
                        </p>
                    </div>
                    <div className="video_wrapper">
                        <iframe
                            className="elementor-video"
                            frameBorder="0"
                            allowFullScreen=""
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            title="Video Placeholder"
                            width="640"
                            height="360"
                            src="https://www.youtube.com/embed/XHOmBV4js_E?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fspecifying.protector.com.au&amp;widgetid=1"
                            id="widget2"
                        ></iframe>
                    </div>
                </div>

                <div className="section2" style={{ paddingBottom: "20px" }}>
                    <h2 style={{ fontSize: "30px" }}>
                        SPECIFYING Protector ® LATEST RELEASES
                    </h2>
                    <p
                        className="article_white"
                        style={{ fontWeight: "400", lineHeight: "1.3em" }}
                    >
                        Specifying Protector ® is being constantly updated with
                        new profiles, technical literature and exclusive tools
                        and resources to help you specify ® range with accuracy
                        and ease. If you have not been granted access to our
                        exclusive content yet, you can request access here.
                    </p>
                    <div className="wrapper-row">
                        <div className="tornato_gpt">
                            <img
                                src="/imgs/home_tornado_gpt-modified.png"
                                alt=""
                                style={{
                                    width: "330px", // Makes the image responsive and fit its container width
                                    height: "330px", // Maintains the aspect ratio
                                    objectFit: "contain",
                                }}
                            />
                            <h2>Tornado-GPT</h2>
                            <p
                                className="article"
                                style={{
                                    marginTop: "0px",
                                    marginBottom: "10px",
                                    fontWeight: "400",
                                    lineHeight: "1.3em",
                                }}
                            >
                                Generate specification documentation with
                                SpeCreator. Ensuring your specifications capture
                                important design, fixing and installation
                                details to protect you and your client’s project
                                needs.
                            </p>
                            <button
                                className="white-button"
                                style={{
                                    background: "none", // Removes any background fill
                                    border: "none", // Removes the border
                                    color: "white", // Makes the text white
                                    cursor: "pointer", // Adds a pointer cursor for better UX
                                    fontWeight: "600",
                                    fontSize: "16px", // Optional: Adjust font size
                                    display: "flex", // Optional: Aligns the icon and text horizontally
                                    alignItems: "center", // Optional: Aligns the icon and text vertically
                                }}
                            >
                                <ConstructionIcon
                                    fontSize="large"
                                    sx={{ paddingRight: "8px" }}
                                />
                                CREATE
                            </button>
                        </div>
                        <div className="hydroprotector">
                            <img
                                src="/imgs/home.hydroprotector-modified.png"
                                alt=""
                                style={{
                                    width: "330px", // Makes the image responsive and fit its container width
                                    height: "330px", // Maintains the aspect ratio
                                    objectFit: "contain",
                                }}
                            />
                            <h2>Hydroprotector</h2>
                            <p
                                className="article"
                                style={{
                                    marginTop: "0px",
                                    marginBottom: "10px",
                                    fontWeight: "400",
                                    lineHeight: "1.3em",
                                }}
                            >
                                Protector® is proud to announce the new KingFlor
                                Design Suite is now available for download. Once
                                logged in you can access the software along with
                                other Fielders® exclusive profiles and their
                                design files.
                            </p>
                            <button
                                className="white-button"
                                style={{
                                    background: "none", // Removes any background fill
                                    border: "none", // Removes the border
                                    color: "white", // Makes the text white
                                    cursor: "pointer", // Adds a pointer cursor for better UX
                                    fontSize: "16px", // Optional: Adjust font size
                                    fontWeight: "600",
                                    display: "flex", // Optional: Aligns the icon and text horizontally
                                    alignItems: "center", // Optional: Aligns the icon and text vertically
                                }}
                            >
                                <ConstructionIcon
                                    fontSize="large"
                                    sx={{ paddingRight: "8px" }}
                                />
                                CREATE
                            </button>
                        </div>
                        <div className="trapIt">
                            <img
                                src="/imgs/home_trap_it-modified.png"
                                alt=""
                                style={{
                                    width: "330px", // Makes the image responsive and fit its container width
                                    height: "330px", // Maintains the aspect ratio
                                    objectFit: "contain",
                                }}
                            />
                            <h2>Trap IT</h2>
                            <p
                                className="article"
                                style={{
                                    marginTop: "0px",
                                    marginBottom: "10px",
                                    fontWeight: "400",
                                    lineHeight: "1.3em",
                                }}
                            >
                                Fielders® is pleased to provide access to CAD
                                and Revit files for BIM. Our exclusive profiles
                                and their design files are available for all
                                logged in users and can be accessed from profile
                                pages and the downloads page.
                            </p>
                            <button
                                className="white-button"
                                style={{
                                    background: "none", // Removes any background fill
                                    border: "none", // Removes the border
                                    color: "white", // Makes the text white
                                    cursor: "pointer", // Adds a pointer cursor for better UX
                                    fontSize: "16px", // Optional: Adjust font size
                                    display: "flex", // Optional: Aligns the icon and text horizontally
                                    alignItems: "center", // Optional: Aligns the icon and text vertically
                                    fontWeight: "600",
                                }}
                            >
                                <ConstructionIcon
                                    fontSize="large"
                                    sx={{ paddingRight: "8px" }}
                                />
                                CREATE
                            </button>
                        </div>
                    </div>
                </div>

                <div className="section1" style={{ backgroundColor: "white" }}>
                    <div className="article_wrapper">
                        <h2 className="title">
                            INNOVATING SUSTAINABLE SOLUTIONS TO PROTECT OUR
                            ENVIRONMENT AND FUTURE
                        </h2>
                        <p className="paragraph-blue" style={{ color: "grey" }}>
                            ONE STORMWATER SOLUTION AT A TIME
                        </p>
                        <p
                            className="paragraph-gray"
                            style={{ color: "black", fontWeight: "400" }}
                        >
                            Protector is a family-run Australian company that
                            specializes in treating stormwater and making
                            fiberglass products. With 18 years of experience,
                            they focus on improving their designs and
                            manufacturing processes. Their stormwater treatment
                            products meet high Australian standards and are
                            known for their quality. Protector has simplified
                            installation by removing the need for confined space
                            entry and deep digging.
                        </p>
                        <p
                            className="paragraph-gray"
                            style={{ color: "black", fontWeight: "400" }}
                        >
                            Our products are made using a special process that
                            makes them strong in all directions. Each product
                            has a smooth inner layer that resists corrosion and
                            an outer protective layer. Made from Fiber
                            Reinforced Plastic (FRP), they are lightweight, easy
                            to handle, and durable. FRP is a sustainable
                            material that lasts longer than traditional
                            materials, doesn’t rust, and withstands tough
                            conditions, which means lower maintenance costs and
                            less environmental impact. Protector’s products are
                            strong, long-lasting, and easy to maintain, making
                            them an excellent choice for stormwater treatment.
                        </p>
                        <div>
                            <button
                                style={{
                                    backgroundColor: "#005f7f", // Button background color
                                    color: "white", // Text color
                                    border: "none", // Remove border
                                    borderRadius: "20px", // Rounded corners
                                    padding: "8px 16px", // Spacing inside the button
                                    fontSize: "16px", // Font size
                                    cursor: "pointer", // Pointer cursor on hover
                                    outline: "none", // Removes focus outline
                                    marginTop: "20px", // Spacing from the top
                                }}
                            >
                                Learn More
                            </button>
                        </div>
                    </div>
                    <div className="video_wrapper">
                        <img src="/imgs/section3_pic.jpg" alt="" />
                    </div>
                </div>
                <div
                    style={{
                        backgroundColor: "#32698f", // Background color of the container
                        display: "flex", // Use flexbox for alignment
                        flexDirection: "column", // Stack items vertically
                        justifyContent: "center", // Center items vertically
                        alignItems: "center", // Center items horizontally
                        textAlign: "center", // Optional: Center-align text
                        paddingBottom: "30px", // Optional: Add padding to the bottom
                        marginBottom: "35px", // Optional: Add margin to the bottom
                    }}
                >
                    <h2
                        style={{
                            color: "white",
                            marginBottom: "16px",
                            fontSize: "30px",
                        }}
                    >
                        DOWNLOAD OUR CAPABILITY STATEMENT
                    </h2>
                    <button
                        style={{
                            backgroundColor: "#32698f", // Button background color
                            color: "white", // Text color
                            border: "2px solid white", // Correctly sets border with width, style, and color

                            borderRadius: "20px", // Rounded corners
                            padding: "8px 16px", // Spacing inside the button
                            fontSize: "16px", // Font size
                            cursor: "pointer", // Pointer cursor on hover
                            fontWeight: "700",
                        }}
                    >
                        DOWNLOAD
                    </button>
                </div>

                <div
                    className="section4"
                    style={{
                        marginBottom: "35px",
                        paddingLeft: "0px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                    }}
                >
                    <div className="wrapper0">
                        <img
                            src="/imgs/computer.png"
                            alt=""
                            style={{
                                width: "100%", // Makes the image responsive and fit its container width
                                height: "auto", // Maintains the aspect ratio
                                objectFit: "contain", // Ensures the image scales within its container
                            }}
                        />
                    </div>

                    <div
                        className="wrapper1"
                        style={{
                            paddingLeft: "50px",
                            paddingTop: "60px",
                        }}
                    >
                        <h2
                            style={{
                                color: "#32698f",
                                fontFamily: "Roboto, Sans-serif",
                                fontWeight: 600,
                                fontSize: "30px",
                                lineHeight: "1.3em",
                            }}
                        >
                            SPECIFYING ® PREMIUM LOGIN ACCESS NOW AVAILABLE
                        </h2>

                        <p
                            style={{
                                color: "black",
                                marginTop: "0px",
                                marginBottom: "70px",
                                fontWeight: "400",
                            }}
                        >
                            Login access to exclusive Specifying Protector®
                            product literature is available now. Once registered
                            you’ll get full access to literature for our premium
                            products as well as full access to all product
                            design files and details. Not a registered user yet?
                            Register
                        </p>
                        <div
                            className="wrapper2"
                            style={{
                                display: "flex",
                                flexDirection: "row", // horizontal alignment
                                gap: "20px", // adds space between buttons
                                alignItems: "center", // vertically centers the buttons
                            }}
                        >
                            <button
                                onClick={() =>
                                    (window.location.href = "/signup")
                                }
                                style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "#000000",
                                    padding: "10px 20px",
                                    paddingLeft: "0px",
                                    marginLeft: "0px",
                                    cursor: "pointer",
                                    fontWeight: 600,
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "20px",
                                }}
                            >
                                <PersonAddAlt1Icon
                                    fontSize="large"
                                    sx={{ marginRight: "15px" }}
                                />
                                REGISTER TODAY
                            </button>
                            <button
                                style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "#000000",
                                    padding: "10px 20px",
                                    cursor: "pointer",
                                    fontWeight: 600,
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "20px",
                                }}
                                onClick={() =>
                                    (window.location.href = "/login")
                                }
                            >
                                <LoginIcon
                                    fontSize="large"
                                    sx={{ marginRight: "15px" }}
                                />
                                LOG IN
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        backgroundColor: "#CBCCD5",
                        background: "linear-gradient(to right, #CBCCD5, white)", // Replace with your desired colors

                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        textAlign: "center",
                        paddingBottom: "30px",
                        marginBottom: "35px",
                        paddingRight: "150px",
                        paddingLeft: "150px",
                        paddingTop: "30px",
                    }}
                >
                    <h2 style={{ fontSize: "30px" }}>
                        EXPLORE OUR RECENT PROJECTS
                    </h2>
                    <button
                        style={{
                            backgroundColor: "#005f7f", // Button background color
                            color: "white", // Text color
                            border: "none", // Remove border
                            borderRadius: "20px", // Rounded corners
                            padding: "12px 16px", // Spacing inside the button
                            fontSize: "16px", // Font size
                            cursor: "pointer", // Pointer cursor on hover
                            outline: "none", // Removes focus outline
                            marginTop: "20px", // Spacing from the top
                            fontWeight: "700",
                        }}
                    >
                        VIEW PROJECTS
                    </button>
                </div>

                <footer
                    style={{
                        justifyContent: "center",
                        backgroundColor: "#424242",
                        height: "70vh",
                    }}
                >
                    {/* <div className="wrapper1">
                        <img
                            className="img1"
                            src="/imgs/Protector-Text-Black-1-300x25.png"
                            alt=""
                        />
                        <img className="img2" src="/imgs/Saving-Nature-e1725214906424.png" alt="" />
                    </div>

                    <div className="links">
                        <span className="span1">Aout</span>
                        <span className="span2">Guarantee And Warranties</span>
                        <span className="span3">Legals</span>
                        <span className="span4">Contact</span>
                    </div> */}
                    <h2 style={{ color: "white" }}>STANDARD WEBSITE FOOTER</h2>
                </footer>
            </div>
        </div>
    );
};

export default ProductHomePage;
