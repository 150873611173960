import axios from 'axios';

// Retrieve the JWT token from localStorage or cookies
const token = localStorage.getItem('jwtToken');

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Replace with your backend URL
 // baseURL: 'http://localhost:5186', // Replace with your backend URL
  headers: {
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` }), // Attach the JWT if available
  },
  withCredentials: true, // Include cookies if needed
});

export default apiClient;
