import React, { useState, useEffect } from "react";
import {
    Alert,
    AlertTitle,
    Button,
    Container,
    TextField,
    Typography,
    Paper,
    Box,
    CircularProgress,
    IconButton,
    Stack,
    Card,
    CardContent,
} from "@mui/material";
import { UploadFile, Close as CloseIcon } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import apiClient from "../../Helpers/apiClient";

const ClientFormPage = () => {
    const { token } = useParams();
    const [isValidToken, setIsValidToken] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        siteOwnerOrStrataManager: "",
        contactNumber: "",
        email: "",
        productCategory: "",
        description: "",
    });
    const [files, setFiles] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const validateToken = async () => {
            if (!token) {
                setError("No token provided.");
                setIsValidToken(false);
                return;
            }

            try {
                const response = await apiClient.get(
                    `/api/projectuser/form/validate/${token}`
                );
                setIsValidToken(true);
            } catch (err) {
                setError(
                    err.response?.data?.message || "Invalid or expired link."
                );
                setIsValidToken(false);
            }
        };

        validateToken();
    }, [token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles(selectedFiles);
    };

    const handleRemoveFile = (indexToRemove) => {
        setFiles(files.filter((_, index) => index !== indexToRemove));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isValidToken || files.length === 0 || !formData.contactNumber) {
            setError(
                "Please ensure all required fields are filled and at least one file is selected. Contact number is required."
            );
            return;
        }

        setIsSubmitting(true);
        setError("");

        const formDataToSend = new FormData();
        files.forEach((file) => {
            formDataToSend.append("files", file);
        });

        const submitData = {
            ...formData,
            productCategory: "",
            description: "",
        };
        formDataToSend.append("details", JSON.stringify(submitData));

        try {
            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };

            await apiClient.post(
                `/api/projectuser/form/submit/${token}`,
                formDataToSend,
                config
            );

            setSuccess(true);
            setFormData({
                name: "",
                siteOwnerOrStrataManager: "",
                contactNumber: "",
                email: "",
                productCategory: "",
                description: "",
            });
            setFiles([]);
        } catch (err) {
            setError(
                err.response?.data?.message ||
                    "Failed to submit form. Please try again."
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!token || !isValidToken) {
        return (
            <Container maxWidth="sm" sx={{ mt: 4 }}>
                <Alert severity="error" variant="filled">
                    <AlertTitle>Error</AlertTitle>
                    {error || "Invalid token"}
                </Alert>
            </Container>
        );
    }

    return (
        <Container maxWidth="md">
            <Box py={4}>
                <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Project Details Submission
                    </Typography>

                    {error && (
                        <Alert severity="error" sx={{ mb: 3 }}>
                            <AlertTitle>Error</AlertTitle>
                            {error}
                        </Alert>
                    )}

                    {success && (
                        <Alert severity="success" sx={{ mb: 3 }}>
                            <AlertTitle>Success</AlertTitle>
                            Form submitted successfully!
                        </Alert>
                    )}

                    <form onSubmit={handleSubmit}>
                        <Stack spacing={3}>
                            <TextField
                                label="Project Name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                                fullWidth
                                variant="outlined"
                            />

                            <TextField
                                label="Site Owner or Strata Manager"
                                name="siteOwnerOrStrataManager"
                                value={formData.siteOwnerOrStrataManager}
                                onChange={handleInputChange}
                                required
                                fullWidth
                                variant="outlined"
                            />

                            <TextField
                                label="Contact Number"
                                name="contactNumber"
                                value={formData.contactNumber}
                                onChange={handleInputChange}
                                required
                                fullWidth
                                variant="outlined"
                                placeholder="Enter your contact number"
                            />

                            <TextField
                                label="Email"
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                fullWidth
                                variant="outlined"
                            />

                            <Box>
                                <Button
                                    component="label"
                                    variant="outlined"
                                    startIcon={<UploadFile />}
                                    sx={{ mb: 2 }}
                                >
                                    Upload Files
                                    <input
                                        type="file"
                                        multiple
                                        onChange={handleFileChange}
                                        hidden
                                        required
                                    />
                                </Button>

                                {files.length > 0 && (
                                    <Stack spacing={1}>
                                        {files.map((file, index) => (
                                            <Card
                                                key={index}
                                                variant="outlined"
                                                sx={{ bgcolor: "grey.50" }}
                                            >
                                                <CardContent
                                                    sx={{
                                                        py: 1,
                                                        "&:last-child": {
                                                            pb: 1,
                                                        },
                                                    }}
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                        >
                                                            {file.name}
                                                        </Typography>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                handleRemoveFile(
                                                                    index
                                                                )
                                                            }
                                                            color="error"
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </Stack>
                                )}
                            </Box>

                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isSubmitting}
                                size="large"
                                sx={{ mt: 2 }}
                            >
                                {isSubmitting ? (
                                    <CircularProgress
                                        size={24}
                                        color="inherit"
                                    />
                                ) : (
                                    "Submit"
                                )}
                            </Button>
                        </Stack>
                    </form>
                </Paper>
            </Box>
        </Container>
    );
};

export default ClientFormPage;
