import React, { useState, useEffect } from "react";
import apiClient from "../../Helpers/apiClient";
import { Navigate } from "react-router-dom";

import {
    Container,
    Paper,
    Typography,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Divider,
    Box,
    TextField,
    CircularProgress,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    Alert,
} from "@mui/material";
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    Link as LinkIcon,
    ArrowBack,
} from "@mui/icons-material";

const WarrantyManagementPage = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [generatedLink, setGeneratedLink] = useState("");
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [projectToDelete, setProjectToDelete] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });

    useEffect(() => {
        apiClient
            .get("/api/projectuser/projects")
            .then((response) => setProjects(response.data))
            .catch((error) => {
                console.error("Error fetching projects:", error);
                showSnackbar("Failed to load projects", "error");
            })
            .finally(() => setLoading(false));
    }, []);

    const showSnackbar = (message, severity = "success") => {
        setSnackbar({ open: true, message, severity });
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleDeleteClick = (project, event) => {
        event.stopPropagation(); // Prevent triggering the ListItem click
        setProjectToDelete(project);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await apiClient.delete(
                `/api/projectuser/projects/${projectToDelete.id}`
            );
            setProjects((prev) =>
                prev.filter((project) => project.id !== projectToDelete.id)
            );
            showSnackbar("Project deleted successfully");
        } catch (error) {
            console.error("Error deleting project:", error);
            showSnackbar("Failed to delete project", "error");
        }
        setDeleteDialogOpen(false);
    };

    const handleGenerateLink = async () => {
        try {
            const response = await apiClient.post(
                "/api/projectuser/generate-link"
            );
            setGeneratedLink(response.data.link);
            showSnackbar("Temporary link generated successfully");
        } catch (error) {
            console.error("Error generating link:", error);
            showSnackbar("Failed to generate link", "error");
        }
    };

    const handleEditClick = (projectId, event) => {
        event.stopPropagation(); // Prevent triggering the ListItem click
        window.location.href = `/warrenty/edit/${projectId}`;
    };

    const handleProjectClick = (projectId) => {
        window.location.href = `/warrenty/detail/${projectId}`;
    };

    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            {/* Temporary Link Section */}
            <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                <Typography variant="h5" component="h2" gutterBottom>
                    Generate Temporary Form
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Button
                        variant="contained"
                        startIcon={<LinkIcon />}
                        onClick={handleGenerateLink}
                        color="primary"
                    >
                        Generate Form
                    </Button>
                    {generatedLink && (
                        <TextField
                            fullWidth
                            value={generatedLink}
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{ ml: 2 }}
                        />
                    )}
                </Box>
            </Paper>

            {/* Projects Section */}
            <Paper elevation={3} sx={{ p: 3 }}>
                <Typography variant="h5" component="h2" gutterBottom>
                    Manage Projects
                </Typography>
                {loading ? (
                    <Box
                        sx={{ display: "flex", justifyContent: "center", p: 3 }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <List>
                        {projects.map((project, index) => (
                            <React.Fragment key={project.id}>
                                {index > 0 && <Divider />}
                                <ListItem
                                    button
                                    onClick={() =>
                                        handleProjectClick(project.id)
                                    }
                                    sx={{
                                        cursor: "pointer",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.04)",
                                        },
                                    }}
                                >
                                    <ListItemText
                                        primary={project.name}
                                        secondary={
                                            project.siteOwnerOrStrataManager
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            aria-label="edit"
                                            onClick={(e) =>
                                                handleEditClick(project.id, e)
                                            }
                                            sx={{ mr: 1 }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={(e) =>
                                                handleDeleteClick(project, e)
                                            }
                                            color="error"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                )}
            </Paper>
            <Box
                sx={{ mb: 3, mt: 2, display: "flex", justifyContent: "center" }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ArrowBack />}
                    onClick={() => (window.location.href = "/dashboard")}
                >
                    Back to Dashboard
                </Button>
            </Box>
            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Delete Project</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this project? This
                        action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteConfirm}
                        color="error"
                        variant="contained"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default WarrantyManagementPage;
