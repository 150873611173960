import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { CloudUpload, Delete, Download, Folder, ArrowBack } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import apiClient from '../Helpers/apiClient';

const Input = styled('input')({
  display: 'none',
});

const FileStoragePage = () => {
  const [files, setFiles] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = React.useRef(null);
  const navigate = useNavigate();
  const { projectId } = useParams();

  useEffect(() => {
    fetchProjectDetails();
    fetchFiles();
  }, [projectId]);

  const fetchProjectDetails = async () => {
    try {
      const response = await apiClient.get(`/api/Project/${projectId}`);
      setProjectName(response.data.name);
    } catch (error) {
      console.error('Error fetching project details:', error);
    }
  };

  const fetchFiles = async () => {
    try {
      const response = await apiClient.get('/api/FileStorage/files', {
        params: { projectId, page: 1, pageSize: 10 },
      });
      setFiles(response.data.files);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setUploading(true);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('projectId', projectId);

    try {
      await apiClient.post('/api/FileStorage/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });
      fetchFiles();
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setUploading(false);
      setUploadProgress(0);
      if (fileInputRef.current) fileInputRef.current.value = '';
    }
  };

  const handleDownload = async (fileId) => {
    try {
      const response = await apiClient.get(`/api/FileStorage/download/${fileId}`, { responseType: 'blob' });
  
      const contentType = response.headers['content-type'] || 'application/octet-stream';
      const contentDisposition = response.headers['content-disposition'];
      let fileName = `file-${fileId}.dwg`; // Default filename fallback
  
      if (contentDisposition) {
        // Updated regex to extract filename with special extensions like ._3D.dwg
        const fileNameMatch = contentDisposition.match(/filename\*?=['"]?(?:UTF-8'')?([^;"']+)/i);
        if (fileNameMatch) {
          fileName = decodeURIComponent(fileNameMatch[1]);
        }
      }
  
      // Ensure filename has the correct extension as fallback
      if (!fileName.endsWith('.dwg') && !fileName.endsWith('._3D.dwg')) {
        fileName += '.dwg'; // Default extension fallback
      }
  
      const blob = new Blob([response.data], { type: contentType });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
  
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  
  
  const handleDelete = async (fileId) => {
    try {
      await apiClient.delete(`/api/FileStorage/delete/${fileId}`);
      fetchFiles();
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  return (
    <Box sx={{ p: 3 }}>
     

      <Card>
        <CardContent>
          <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
            <Grid item xs={12}>
              <Typography variant="h5">{projectName} - Files</Typography>
            </Grid>
            <Grid item>
              <label htmlFor="file-upload">
                <Input
                  id="file-upload"
                  type="file"
                  onChange={handleFileUpload}
                  disabled={uploading}
                  ref={fileInputRef}
                />
                <Button variant="contained" component="span" startIcon={<CloudUpload />} disabled={uploading}>
                  Upload File
                </Button>
              </label>
            </Grid>
          </Grid>

          {uploading && (
            <Box sx={{ mb: 2 }}>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>
          )}

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Size</TableCell>
                  <TableCell>Upload Date</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {files.map((file) => (
                  <TableRow key={file.id}>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Folder sx={{ mr: 1 }} />
                        {file.fileName}
                      </Box>
                    </TableCell>
                    <TableCell>{formatFileSize(file.size)}</TableCell>
                    <TableCell>{new Date(file.uploadDate).toLocaleDateString()}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleDownload(file.id)} size="small">
                        <Download />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(file.id)} size="small" color="error">
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <Box sx={{ mb: 3, mt: 2, display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ArrowBack />}
          onClick={() => navigate('/projects')}
        >
          Back to Projects
        </Button>
      </Box>
    </Box>
  );
};

export default FileStoragePage;
