import React, { useState, useEffect } from "react";
import apiClient from "../../Helpers/apiClient";

const AdminProjectListPage = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        apiClient
            .get("/api/projectuser/projects")
            .then((response) => setProjects(response.data))
            .catch((error) => console.error("Error fetching projects:", error))
            .finally(() => setLoading(false));
    }, []);

    const handleDelete = async (id) => {
        if (!window.confirm("Are you sure you want to delete this project?"))
            return;

        try {
            await apiClient.delete(`/api/projectuser/projects/${id}`);
            setProjects((prev) => prev.filter((project) => project.id !== id));
        } catch (error) {
            console.error("Error deleting project:", error);
        }
    };

    return (
        <div>
            <h1>Manage Projects</h1>
            {loading ? (
                <p>Loading projects...</p>
            ) : (
                <ul>
                    {projects.map((project) => (
                        <li key={project.id}>
                            <strong>{project.name}</strong> -{" "}
                            {project.siteOwnerOrStrataManager}
                            <button
                                onClick={() =>
                                    (window.location.href = `/admin/projects/edit/${project.id}`)
                                }
                            >
                                Edit
                            </button>
                            <button onClick={() => handleDelete(project.id)}>
                                Delete
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AdminProjectListPage;
