import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
    Container,
    Paper,
    Typography,
    Box,
    Grid,
    CircularProgress,
    Alert,
    Divider,
} from "@mui/material";
import apiClient from "../../Helpers/apiClient";
import { ArrowBack } from "@mui/icons-material";
import Button from "@mui/material/Button";

const WarrentyDetailsPage = () => {
    const { id } = useParams();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await apiClient.get(
                    `/api/projectuser/projects/${id}`
                );
                setProject(response.data);
                console.log("Project data:", response.data); // Debug log
            } catch (error) {
                console.error("Error fetching project:", error);
                setError(
                    typeof error.response?.data === "string"
                        ? error.response.data
                        : "Failed to load project details. Please try again."
                );
            } finally {
                setLoading(false);
            }
        };

        fetchProject();
    }, [id]);

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        });
    };

    if (loading) {
        return (
            <Container maxWidth="md">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="60vh"
                >
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (error) {
        return (
            <Container maxWidth="md">
                <Box py={4}>
                    <Alert severity="error">{error}</Alert>
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="md">
            <Box py={4}>
                <Paper elevation={3}>
                    <Box p={4}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            Project Details
                        </Typography>

                        <Divider sx={{ my: 3 }} />

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                    gutterBottom
                                    color="primary"
                                >
                                    {project.name}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    Site Owner/Manager
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {project.siteOwnerOrStrataManager}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    Product Category
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {typeof project.productCategory === "string"
                                        ? project.productCategory.replace(
                                              /_/g,
                                              " "
                                          )
                                        : project.productCategory || "None"}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    Contact Number
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {project.contactNumber}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    Email
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {project.email}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    Description
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    {project.description ||
                                        "No description provided"}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                    gutterBottom
                                >
                                    Project Photos
                                </Typography>
                                <Box sx={{ mt: 2, mb: 3 }}>
                                    <Grid container spacing={2}>
                                        {project.photos &&
                                        project.photos.length > 0 ? (
                                            project.photos.map((photo) => (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                    key={photo.id}
                                                >
                                                    <Paper
                                                        elevation={2}
                                                        sx={{
                                                            p: 1,
                                                            height: "200px",
                                                            overflow: "hidden",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                            transition:
                                                                "transform 0.2s",
                                                            "&:hover": {
                                                                transform:
                                                                    "scale(1.02)",
                                                                cursor: "pointer",
                                                            },
                                                        }}
                                                    >
                                                        <img
                                                            src={photo.url}
                                                            alt={`Project photo ${photo.id}`}
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                objectFit:
                                                                    "cover",
                                                            }}
                                                            onClick={() =>
                                                                window.open(
                                                                    photo.url,
                                                                    "_blank"
                                                                )
                                                            }
                                                        />
                                                    </Paper>
                                                </Grid>
                                            ))
                                        ) : (
                                            <Grid item xs={12}>
                                                <Typography color="textSecondary">
                                                    No photos available
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider sx={{ my: 2 }} />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography
                                    variant="subtitle2"
                                    color="textSecondary"
                                >
                                    Created At
                                </Typography>
                                <Typography variant="body2">
                                    {formatDate(project.createdAt)}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography
                                    variant="subtitle2"
                                    color="textSecondary"
                                >
                                    Last Updated
                                </Typography>
                                <Typography variant="body2">
                                    {formatDate(project.updatedAt)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
                <Box
                    sx={{
                        mb: 3,
                        mt: 2,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ArrowBack />}
                        onClick={() => (window.location.href = "/warrenty")}
                    >
                        Go Back
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default WarrentyDetailsPage;
